.mt-auto {
    margin-top: auto;
}

.mb-auto {
    margin-bottom: auto;
}


.gravity-green {
    color: $gravity-green;
}
