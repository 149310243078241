/*******************************
    Gravity Instant Style Guide
*******************************/

$site-font: proxima-nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$site-font-size: 16px;
$site-padding: 10px;

$font-secondary: 'brandon-grotesque', sans-serif;

//Colors
$gravity-green: #91c73d;
$gravity-blue: #33465C;

$white: #FFF;
$black: #000;

$gray: #ececec;
$light-gray: #f9f9fb;
$dark-gray: #2a2a2a;

$slightly-darker-green: #4b964b;
$green: #54A754;
$light-green: #91c7 3d;
$dark-green: #35A556;

$blue: #3487B2;
$blue-gray: #8a97a0;
$navy:	#253750;

$red: #ff4136;

$transition-fast:		0.15s;

$tablet: "(min-width: 768px)";
$desktop: "(min-width: 992px)";
$lg-desktop: "(min-width: 1200px)";

:root {
    --mdc-theme-primary: #91c73d;
}

