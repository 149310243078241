html {
    box-sizing: border-box;
    height: 100%;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}

*, *:before, *:after {
box-sizing: inherit;
}

body {
    color: $dark-gray;
    display: flex;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $site-font;
    font-size: $site-font-size;
    line-height: 1.5rem;

    input:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
}

#root {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;



    @media #{$tablet} {
        min-height: 100vh;
    }
}

.App {
    @media #{$tablet} {
        width: 750px;
    }

    @media #{$desktop} {

        width: 1024px;
    }
    @media ($lg-desktop) {
        width: 1170px;
    }
}
